




import {Component, Vue} from 'vue-property-decorator';
import {loginService} from '@/services/login.service';
import {BizSirenResultDto} from '@/services/LoginModels';

@Component
export default class BizSirenCallBackIpin extends Vue {
  private retInfo: string = this.$route.query.retInfo as string;
  private type = 'ipin';

  mounted() {
    console.log(this.retInfo);
    if (this.retInfo) {
      loginService.getBizSirenPhoneDecode(this.type, this.retInfo).then(response => {
        if (response.status == 200) {
          const responseDataRet = response.data as BizSirenResultDto;
          console.log(responseDataRet);
          window.opener.recvSirenPhoneResult(this.type, responseDataRet);
          window.close();
        } else {
          //http response 오류 처리
          this.$bvModal
            .msgBoxOk('인증정보를 처리시 알수없는 오류가 발생했습니다', {
              title: '오류발생',
              okVariant: 'danger',
              centered: true,
            })
            .then(value => {
              window.close();
            })
            .catch(err => {
              window.close();
            });
        }
      });
    }
  }
}
